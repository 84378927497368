import React from "react"
import Layout from "../../components/layout"

export default function Support() {
  return (
        <Layout>
        <div className="text-left mx-auto max-w-2xl">
        <h1>Tap Tap Cap Support & FAQ</h1>

        <p>
        <h2 className="font-bold">Have a question? Here are some common issues with answers. If you still need help, feel free to email <a href="mailto:support@taptapcap.com" className="underline">support@taptapcap.com</a></h2>
        </p>

        <hr className="mb-8" />

        <h3 className="font-bold">I'm not seeing my entire photo library</h3>
        <p>Our app is optimized for video use, if you are not seeing all your videos please check that your photo perimsssions are set to "Allow access to All Photos". You can check this by going to <code className="bg-white bg-opacity-25 text-sm p-1">iPhone Settings -> TapTapCap -> Photos -> Make sure to select "All Photos"</code>
        </p>

        <h3 className="font-bold">My exports are not showing in my Photo Library</h3>
        <p>After exporting your video make sure to press the "Save Video" button located on the bottom left hand corner of the screen
        </p>

        <h3 className="font-bold">How do I remove the Tap Tap Cap watermark?</h3>
        <p>While Tap Tap Cap is offered for free we currently do not have an option
        to remove the watermark.</p>

        <h3 className="font-bold">The captions appear and disppear too quickly</h3>
        <p>Tap on the caption or the caption color bar within the timeline, then drag the end point on the bar to extend how long the caption is visible for.</p>

        <h3 className="font-bold">Why is my Stories upload to Instagram stories cut off?</h3>
        <p>Instagram stories only allows up to 15 second clips.</p>

        <p className="italic">Tip: IG now automatically cuts up longer videos added to Stories. To use this feature, use the Instagram app to create a story by tapping the Camera icon in the top left of the home screen. Select your exported StorySwag video and the next screen will automatically split the video.</p>

        <h3 className="font-bold">How do I upgrade to Pro?</h3>
        <p>You don't have to! Tap Tap Cap is free through 2020.</p>

        <h3 className="font-bold">How do I give the app access to my photos/camera?</h3>
        <p>Go to your device’s <code className="bg-white bg-opacity-25 text-sm p-1">Settings -> Tap Tap Cap</code> and turn on access to camera.</p>

        </div>
        </Layout>
  )
}
